<template>
  <BaseModal
    v-model="isActive"
    :has-modal-card="true"
    :trap-focus="true"
  >
    <CardPopup
      title="Add Custom Translation"
      @hide="setModalVisibilty(false)"
    >
      <template #body>
        <b-field label="Name">
          <b-input
            v-model="customTranslation.name"
            required
            placeholder="Enter Name"
          />
        </b-field>
        <b-field
          v-for="(language, lIndex) in localizationStore.supportedLanguages"
          :key="'lang-col' + lIndex"
          :label="`${language.label} Translation`"
        >
          <b-input
            v-model="customTranslation.translations[language.id]"
            placeholder="Enter Translation"
          />
        </b-field>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="setModalVisibilty(false)"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            :loading="isCreatingTranslation"
            :disabled="!customTranslation.name"
            @click="createCustomTranslation()"
          >
            Create
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>
    
<script >
// libs
import { computed, ref, watch } from '@vue/composition-api';
// components
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
// stores
import { useLocalizationStore } from '@/modules/builder/store/localizationStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { uuid } from 'vue-uuid';
import { useBuefy } from '@/hooks/buefy';
import { refResetter } from '@/hooks/utils';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const [customTranslation, resetCustomTranslation] = refResetter({
    name: '',
    translations: {}
  });
  const route = useRoute();
  const buefy = useBuefy();
  const localizationStore = useLocalizationStore();
  const isCreatingTranslation = ref(false);
  const isActive = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const setModalVisibilty = visibility => {
    isActive.value = visibility;
  };
  const createCustomTranslation = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      const payload = {
        ...customTranslation.value,
        scope: 'GLOBAL',
        reference: uuid.v4()
      };
      isCreatingTranslation.value = true;
      await localizationStore.createTranslation(appId, moduleId, payload);
      buefy.toast.open({
        message: 'Translation added!',
        type: 'is-success',
        duration: 3000
      });
      setModalVisibilty(false);
    } catch (err) {
      console.error(err);
      buefy.toast.open({
        message: 'Failed to add translation',
        type: 'is-danger',
        duration: 3000
      });
    } finally {
      isCreatingTranslation.value = false;
    }
  };
  watch(() => isActive.value, () => {
    if (!isActive.value) {
      resetCustomTranslation();
    }
  });
  return {
    customTranslation,
    localizationStore,
    isCreatingTranslation,
    isActive,
    setModalVisibilty,
    createCustomTranslation
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>
