<template>
  <div>
    <div v-if="!isEditing">
      <span
        class="edit-toggle"
        @click="startEditing"
      >
        {{ value }}
      </span>
    </div>
    <div
      v-else
      class="is-flex"
    >
      <b-input
        v-model="editedValue"
        size="is-small"
        placeholder="Edit value"
      />
      <b-button
        :loading="buttonLoading === 'SAVE'"
        icon-right="check"
        size="is-small"
        type="is-success"
        class="is-clickable ml-2"
        @click="save"
      />
      <b-button
        :loading="buttonLoading === 'CANCEL'"
        icon-right="close"
        size="is-small"
        type="is-danger"
        class="is-clickable ml-2"
        @click="cancel"
      />
    </div>
  </div>
</template>
  
<script >
import { ref, watch } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    required: true
  },
  buttonLoading: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isEditing = ref(false);
  const editedValue = ref(props.value);
  const startEditing = () => {
    isEditing.value = true;
  };
  const save = () => {
    isEditing.value = false;
    emit('update', editedValue.value);
  };
  const cancel = () => {
    isEditing.value = false;
    editedValue.value = props.value;
    emit('cancel');
  };
  watch(() => props.value, newValue => {
    editedValue.value = newValue;
  });
  return {
    isEditing,
    editedValue,
    startEditing,
    save,
    cancel
  };
};
export default __sfc_main;
</script>
  
<style scoped>
.edit-toggle {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 1.25rem;
}
</style>
