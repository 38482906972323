import { ref } from '@vue/composition-api';

export const generateLangauge = () => ({
    queryParam: '',
    isoCode: '',
    label: '',
    isDisabled: false,
});

const language = ref(generateLangauge());

export const useLanguageManager = () => {
    return {
        language
    };
};
