<template>
  <section class="section">
    <div class="is-flex is-justify-content-end mb-3">
      <b-switch
        v-model="localizationStore.localizationSettings.isEnabled"
        :disabled="isTableLoading"
        @input="updateLocalizationSettings()"
      >
        Enable Localization
      </b-switch>
    </div>
    <div class="columns mt-0 mb-0">
      <!-- Language Selector -->
      <b-field
        label="Default language"
        horizontal
        class="column is-3 language-selector"
      >
        <b-select
          v-model="localizationStore.localizationSettings.defaultLanguage"
          placeholder="Select a language"
          size="is-small"
          :disabled="isTableLoading"
          @input="updateLocalizationSettings()"
        >
          <option value="">
            None
          </option>
          <option
            v-for="(language, lIndex) in localizationStore.supportedLanguages"
            :key="'default-lang-option' + lIndex"
            :value="language.id"
          >
            {{ language.label }}
          </option>
        </b-select>
      </b-field>

      <div class="columns column is-9 is-justify-content-end pr-0">
        <div class="column is-4" />
        <div class="column is-8 is-flex is-justify-content-flex-end pr-0">
          <div>
            <b-button
              v-if="localizationStore.supportedLanguages && localizationStore.supportedLanguages.length"
              type="is-primary"
              size="is-small"
              class="mr-3"
              :disabled="isTableLoading"
              @click="synchronizeTranslations()"
            >
              Sync With Module
            </b-button>
            <b-button
              v-if="localizationStore.supportedLanguages && localizationStore.supportedLanguages.length"
              type="is-primary"
              size="is-small"
              class="mr-3"
              :disabled="isTableLoading"
              @click="setCustomTranslationModalVisibility(true)"
            >
              Add Custom Translation
            </b-button>
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="isTableLoading"
              @click="languageManager.setLanguageModalVisibility(true, 'ADD')"
            >
              Add Language
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Translations Table -->
    <b-table
      v-if="localizationStore.supportedLanguages.length && isTableVisible"
      :data="localizationStore.moduleTranslations"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :paginated="true"
      :per-page="20"
      :total="localizationStore.moduleTranslations.length"
      :sticky-header="true"
      :loading="isTableLoading"
      pagination-size="is-small"
      default-sort-direction="asc"
      class="translations-table"
    >
      <b-table-column
        field="name"
        label="Name"
        sortable
        searchable
      >
        <template #default="{ row }">
          {{ row.name }}
        </template>
      </b-table-column>

      <b-table-column
        field="fieldType"
        label="Linked Field Type"
        sortable
        searchable
      >
        <template #default="{ row }">
          {{ row.fieldType }}
        </template>
      </b-table-column>


      <b-table-column
        v-for="(language, lIndex) in localizationStore.supportedLanguages"
        :key="'lang-col' + lIndex"
        :field="'translations.' + language.id"
        sortable
        searchable
        class="translation-columns"
      >
        <template #header>
          <span>{{ language.label }} Translations</span>
          <b-icon
            icon="pencil"
            size="is-small"
            class="is-clickable"
            type="is-info"
            @click.native.stop="languageManager.setLanguageModalVisibility(true, 'EDIT', language)"
          />
          <b-icon
            icon="trash-can"
            size="is-small"
            class="is-clickable"
            type="is-danger"
            @click.native.stop="languageManager.handleDeletingLanguage(language)"
          />
        </template>
        <template #default="{ row }">
          <BaseEditableCell
            :value="row.translations[language.id]"
            @update="saveTranslation(row, language, $event)"
          />
        </template>
      </b-table-column>
    </b-table>

    <NoResults
      v-else-if="localizationStore.supportedLanguages.length === 0"
      banner-label="No languages yet, create one now."
      button-label="New Language"
      :button-handler="() => languageManager.setLanguageModalVisibility(true, 'ADD')"
    />

    <!-- Language Modal -->
    <LanguageManagerModal 
      v-model="isLanguageModalActive"
      :mode="languageModalMode"
      :selected-language="selectedLanguage"
      @update="synchronizeTranslations()"
    />

    <!-- Delete Language Confirmation Modal -->
    <ConfirmDeleteModal
      v-model="isDeleteLanguageModalActive"
      :entity-name="selectedLanguage ? selectedLanguage.label + ' Translations' : 'Translations'"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Confirm Delete Language"
      aria-modal
      @delete="languageManager.deleteLangauge()"
    />

    <!-- Custom Translation Modal -->
    <CustomTranslationModal 
      v-model="isCustomTranslationModalActive"
    />
  </section>
</template>

<script >
// libs
import { ref } from '@vue/composition-api';
// components
import LanguageManagerModal from './LanguageManagerModal.vue';
import CustomTranslationModal from './CustomTranslationModal.vue';
import ConfirmDeleteModal from '@/modules/core/components/ConfirmDeleteModal.vue';
import BaseEditableCell from '@/modules/core/components/generics/base-table/BaseEditableCell.vue';
import NoResults from '@/modules/core/components/NoResults.vue';
// stores
import { useLocalizationStore } from '@/modules/builder/store/localizationStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import { nextFrame } from '@/helpers/util';

// composables specific to this component
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const useLanguageManager = () => {
    const isLanguageModalActive = ref(false);
    const languageModalMode = ref('');
    const isDeleteLanguageModalActive = ref(false);
    const selectedLanguage = ref(null);

    /**
     * @param visibility {boolean}
     * @param mode {'ADD' | 'EDIT'}
     * @param language {object}
     */
    const setLanguageModalVisibility = (visibility, mode, language) => {
      isLanguageModalActive.value = visibility;
      languageModalMode.value = mode;
      selectedLanguage.value = language;
    };

    /**
     * @param visibility {boolean}
     */
    const setDeleteLanguageModalVisibility = visibility => {
      isDeleteLanguageModalActive.value = visibility;
    };
    const handleDeletingLanguage = language => {
      selectedLanguage.value = language;
      languageManager.setDeleteLanguageModalVisibility(true);
    };
    const deleteLangauge = async () => {
      try {
        isTableLoading.value = true;
        const {
          appId,
          moduleId
        } = route.params;
        const updatedLanguages = localizationStore.supportedLanguages.filter(lang => lang.id !== selectedLanguage.value.id);
        const payload = {
          ...localizationStore.localizationSettings,
          supportedLanguages: updatedLanguages
        };
        languageManager.setDeleteLanguageModalVisibility(false);
        await localizationStore.updateLocalizationSettings(appId, moduleId, payload);
        await synchronizeTranslations();
        isTableVisible.value = false; // @NOTE: this is a work around to re-render table to show updated columns (buefy table doesn't synchronize if language column is deleted) 
        await nextFrame();
        isTableVisible.value = true;
      } catch (err) {
        console.error(err);
      } finally {
        isTableLoading.value = false;
      }
    };
    return {
      isLanguageModalActive,
      isDeleteLanguageModalActive,
      languageModalMode,
      selectedLanguage,
      setLanguageModalVisibility,
      setDeleteLanguageModalVisibility,
      handleDeletingLanguage,
      deleteLangauge
    };
  };
  const route = useRoute();
  const buefy = useBuefy();
  const localizationStore = useLocalizationStore();
  const languageManager = useLanguageManager();
  const {
    isLanguageModalActive,
    languageModalMode,
    isDeleteLanguageModalActive,
    selectedLanguage
  } = languageManager;
  const isTableLoading = ref(false);
  const isTableVisible = ref(true);
  const isCustomTranslationModalActive = ref(false);
  const setCustomTranslationModalVisibility = visibility => {
    isCustomTranslationModalActive.value = visibility;
  };
  const saveTranslation = async (row, language, eventPayload) => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await localizationStore.updateTranslation(appId, moduleId, row.id, {
        translation: eventPayload
      }, {
        lang_id: language.id
      });
    } catch (err) {
      console.error(err);
    }
  };
  const synchronizeTranslations = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      isTableLoading.value = true;
      await localizationStore.synchronizeTranslation(appId, moduleId);
      buefy.toast.open({
        message: 'Translations synchronized!',
        type: 'is-success',
        duration: 3000
      });
    } catch (err) {
      console.error(err);
      buefy.toast.open({
        message: 'Failed to synchronize translations',
        type: 'is-danger',
        duration: 3000
      });
    } finally {
      isTableLoading.value = false;
    }
  };
  const updateLocalizationSettings = async () => {
    const {
      appId,
      moduleId
    } = route.params;
    await localizationStore.updateLocalizationSettings(appId, moduleId, localizationStore.localizationSettings);
  };
  return {
    localizationStore,
    languageManager,
    isLanguageModalActive,
    languageModalMode,
    isDeleteLanguageModalActive,
    selectedLanguage,
    isTableLoading,
    isTableVisible,
    isCustomTranslationModalActive,
    setCustomTranslationModalVisibility,
    saveTranslation,
    synchronizeTranslations,
    updateLocalizationSettings
  };
};
__sfc_main.components = Object.assign({
  BaseEditableCell,
  NoResults,
  LanguageManagerModal,
  ConfirmDeleteModal,
  CustomTranslationModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
.section {
  padding: 20px;
}
.language-selector {
  margin-bottom: 0 !important;
  .field-label {
    text-align: left;
    flex-grow: 4;
  }
}
.search-input {
  .field {
    justify-content: flex-end;
  }
}
.translations-table {
  .table {
    table-layout: fixed;
  }
}
</style>

